<template>
    <div>
        <TopHeadline :headerTitle="headerTitle" :backTitle="backTitle" :showGoBack="showGoBack" :onGoBack="onGoBack">
            <template #headerRight>
                <slot name="headerLeft"></slot>
                <slot name="delete">
                    <button type="button" name="delete" class="btn btn-primary" @click="handleDelete" v-if="showDelete == true">
                        {{ $t('common.delete') }}
                    </button>
                </slot>
            </template>
        </TopHeadline>
        <Accordion :headerTitle="searchBoxTitle" v-if="showSearchPanel">
            <template v-slot:body>
                <slot name="searchBox">
                    Empty search Form
                </slot>
                <div class="form-group row">
                    <div class="text-center col-12">
                        <button type="button" id="search-categories" class="btn btn-primary btn-search" @click="handleSearch">
                            <i class="fas fa-search"></i>
                            {{ $t('common.search') }}
                        </button>
                    </div>
                </div>
            </template>
        </Accordion>


        <CCard>
            <CCardHeader>
                <slot name="header">
                    <CIcon name="cil-grid" />
                    {{ $t('common.list') }}
                </slot>
            </CCardHeader>
            <CCardBody>
                <slot name="list">
                    No Content
                </slot>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
    import TopHeadline from './TopHeadline.vue'
    import Accordion from './Accordion.vue'
    import i18n from '@/plugins/i18n'

    export default {
        name: 'ListForm',
        props: {
            showSearchPanel: {
                default: true,
                type: Boolean
            },
            headerTitle: {
                default: "Default Header",
                type: String
            },
            backTitle: {
                default: "Back",
                type: String
            },
            showGoBack: {
                default: false,
                type: Boolean
            },
            showDelete: {
                default: false,
                type: Boolean
            },
            searchBoxTitle: {
                default: i18n.t('common.search'),
                type: String
            },
            onSearch: { type: Function },
            onGoBack: { type: Function },
            onDelete: { type: Function },
        },
        data() {
            return {
            };
        },
        components: { TopHeadline, Accordion },
        methods: {
            handleSearch() {
                if (this.onSearch instanceof Function)
                    this.onSearch();
            },
            handleDelete() {
                if (this.onDelete instanceof Function)
                    this.onDelete();
            },
        }
    }
</script>

<style scoped>
    .card {
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border-radius: .25rem;
    }

        .card .card-header {
            background-color: #fff;
        }

    h5 {
        font-size: 1.09375rem;
    }

        h5 a {
            /* color: #fff;*/
            color: #444;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }
</style>
