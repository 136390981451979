var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ListForm',{attrs:{"headerTitle":_vm.$t('pages.emails.emailTemplateListing'),"onSearch":_vm.onSearch},scopedSlots:_vm._u([{key:"headerLeft",fn:function(){return [_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","name":"add-emailTemplate"},on:{"click":_vm.onAddNewTemplate}},[_vm._v(" "+_vm._s(_vm.$t('pages.emails.addNewTemplate'))+" ")])]},proxy:true},{key:"searchBox",fn:function(){return [_c('CForm',[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('CInput',{attrs:{"label":_vm.$t('pages.emails.templateName'),"placeholder":_vm.$t('pages.emails.enterName'),"required":"","horizontal":""},model:{value:(_vm.searchTemplateName),callback:function ($$v) {_vm.searchTemplateName=$$v},expression:"searchTemplateName"}})],1),_c('CCol',{attrs:{"md":"6"}})],1)],1)]},proxy:true},{key:"list",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.emailTemplateList,"fields":_vm.fields,"noItemsView":{ noItems: _vm.$t('common.nodatafound') },"hover":"","striped":"","border":"","small":"","fixed":"","loading":_vm.isLoading,"pagination":"","items-per-page":_vm.itemsPerPage,"items-per-page-select":{
                                label : _vm.$t('common.recordsperpage'),
                                values : [10,20,50,100]
                                }},on:{"pagination-change":_vm.onItemsPerPageChange},scopedSlots:_vm._u([{key:"bccEmailAddresses_text",fn:function(ref){
                                var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('span',[_vm._v(_vm._s(item.bccEmailAddresses == null ? "" : item.bccEmailAddresses))])])]}},{key:"status_text",fn:function(ref){
                                var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('span',[_vm._v(_vm._s(_vm.showStatus(item.isActive)))])])]}},{key:"delayBeforeSend_text",fn:function(ref){
                                var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('span',[_vm._v(_vm._s(item.delayBeforeSend == null ? "0" : item.delayBeforeSend))])])]}},(_vm.emailTemplateList != null && _vm.emailTemplateList.length > 0)?{key:"footer",fn:function(){return [_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":6}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.totalRecords'))+": ")]),_c('span',{staticStyle:{"padding-left":"5px","color":"crimson","font-weight":"bold"}},[_vm._v(_vm._s(_vm.emailTemplateList == null ? 0: _vm.emailTemplateList.length))])])])])]},proxy:true}:null,{key:"emails_detail_actions",fn:function(ref){
                                var item = ref.item;
                                var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onEditEmailTemplate(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")]),_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onDeleteEmailTemplate(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")])],1)]}}],null,true)})]},proxy:true}])}),_c('Confirmation',{ref:"confirmation"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }