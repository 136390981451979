<template>
    <div>
		<CRow>
            <CCol sm="12">
                <ListForm :headerTitle="$t('pages.emails.emailTemplateListing')" :onSearch="onSearch">
                    <template v-slot:headerLeft>
                        <button type="button" name="add-emailTemplate" class="btn btn-success" @click="onAddNewTemplate">
                            {{ $t('pages.emails.addNewTemplate') }}
                        </button>
                    </template>
                    <template v-slot:searchBox>
                        <CForm>
                            <CRow>
                                <CCol md="6">
                                    <CInput :label="$t('pages.emails.templateName')" :placeholder="$t('pages.emails.enterName')"
                                            v-model="searchTemplateName" required horizontal>
                                    </CInput>
                                </CCol>
                                <CCol md="6">                                   
                                </CCol>
                            </CRow>
                        </CForm>
                    </template>
                    <template v-slot:list>
                        <CDataTable :items="emailTemplateList"
                                    :fields="fields"
                                    :noItemsView="{ noItems: $t('common.nodatafound') }"
                                    hover
                                    striped
                                    border
                                    small
                                    fixed
                                    :loading="isLoading"
                                    pagination
                                    :items-per-page="itemsPerPage"
                                    @pagination-change="onItemsPerPageChange"
                                    :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100]
                                }">
                            <template #bccEmailAddresses_text="{item}">
                                <td class="py-2">
                                    <span>{{ item.bccEmailAddresses == null ? "" :  item.bccEmailAddresses }}</span>
                                </td>
                            </template>
                            <template #status_text="{item}">
                                <td class="py-2">
                                    <span>{{ showStatus(item.isActive) }}</span>
                                </td>
                            </template>
                            <template #delayBeforeSend_text="{item}">
                                <td class="py-2">
                                    <span>{{ item.delayBeforeSend == null ? "0" :  item.delayBeforeSend }}</span>
                                </td>
                            </template>
                            <template #footer v-if="emailTemplateList != null && emailTemplateList.length > 0">
                                <tfoot>
                                    <tr>
                                        <td :colspan="6">
                                            <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{emailTemplateList == null ? 0: emailTemplateList.length}}</span>
                                        </td>
                                    </tr>
                                </tfoot>
                            </template>
                            <template #emails_detail_actions="{item, index}">
                                <td class="py-2">
                                    <CButton color="primary"
                                             variant="outline"
                                             square
                                             size="sm"
                                             @click="onEditEmailTemplate(item, index)">
                                        {{ $t('common.edit') }}
                                    </CButton>
                                    <CButton color="primary"
                                             variant="outline"
                                             square
                                             size="sm"
                                             @click="onDeleteEmailTemplate(item, index)">
                                        {{ $t('common.remove') }}
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </template>
                </ListForm>
                <Confirmation ref="confirmation"></Confirmation>
            </CCol>
        </CRow>
	</div>
</template>

<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'
    import { mapGetters, mapActions } from 'vuex'
    
    export default {
        name: 'EmailTemplateList',
		data() {            
			return {                
				itemsPerPage: 10,
                warningModal: false,                          
                fields: [
                    { key: 'name', label: i18n.t('pages.emails.templateName') },
                    { key: 'subject', label: i18n.t('pages.emails.subject') },
                    { key: 'bccEmailAddresses_text', label: i18n.t('pages.emails.bccEmailAddresses') },
                    { key: 'status_text', label: i18n.t('common.status') },
                    { key: 'delayBeforeSend_text', label: i18n.t('pages.emails.delayBeforeSend') },
                    {
                        key: 'emails_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ],
                searchTemplateName: ''               
            };
		},
        components: { Confirmation, ListForm },
        computed: {            
            ...mapGetters('emailTemplate', ['isLoading', 'emailTemplateList'])
        },
        methods: {
            ...mapActions('emailTemplate', ['searchEmailTemplate']),
            showStatus(isActive) {                
                if (isActive)
                    return i18n.t('common.active');
                return i18n.t('common.inactive');
            },
            onDeleteEmailTemplate(item) {
                this.$refs.confirmation.show(i18n.t('pages.emails.confirmDeleteEmailTemplate'), async () => {
                   
                    var retVal = await this.$store.dispatch("emailTemplate/deleteEmailTemplate", item.id);
                   
                    if (retVal == true)
                    {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.emails.noticeDeleteEmailTemplateSuccess') }, { root: true });
                        this.onSearch();
                    }
                    else
                        this.$store.dispatch('alert/error', i18n.t('pages.emails.noticeDeleteEmailTemplateFailed'), { root: true });
                });
            },
            onEditEmailTemplate(item) {                
                this.$router.push('/emailtemplate/edit/' + item.id);
            },
            onAddNewTemplate() {
                this.$router.push('/emailtemplate/add');
            },
            onItemsPerPageChange(item) {
                localStorage.setItem('emailtemplateItemsPerPage', item)
            },
            onSearch() {                
                this.searchEmailTemplate(this.searchTemplateName);
            }
		},
        created() {           
            this.onSearch();
            
            let emailtemplateItemsPerPage = parseInt(localStorage.getItem('emailtemplateItemsPerPage'));
            if (isNaN(emailtemplateItemsPerPage))
                emailtemplateItemsPerPage = 10;
            this.itemsPerPage = emailtemplateItemsPerPage;
        }
	}
</script>